import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <div className='bg-[#0a192f] text-center text-gray-300 p-2'>
      <p>@2024 Philip T. Nguyen</p>
      <div className='flex justify-center space-x-6 mt-2 p-2'>
        <a href="https://www.linkedin.com/in/philip-toan-nguyen/" target="linkdin" className='hover:scale-110 transform transition duration-200'>
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>
        <a href="https://github.com/PhilipToanNguyen" target="github" className='hover:scale-110 transform transition duration-200'>
          <FontAwesomeIcon icon={faGithub} size="2x" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
