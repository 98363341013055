import React, { useState } from 'react';
import { HiArrowNarrowLeft, HiArrowNarrowRight } from 'react-icons/hi';
import { Link } from 'react-scroll';
import Me from '../assets/me5.png';
import OmMeg from '../assets/ommegundraw.svg';
import Ferdigheter from '../assets/ferdigheterundraw.svg';
import Prosjekter from '../assets/prosjekterundraw.svg';
import Kontakt from '../assets/takontaktundraw.svg';
import { Fade, Slide } from "react-awesome-reveal";
import { TypeAnimation } from 'react-type-animation';

const Home = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className='w-full h-screen bg-[#0a192f]'>
      <div name='home' className='w-full h-screen bg-[#0a192f] flex justify-center'>
        {/* Container */}
        <div className='w-full h-screen max-w-[1000px] grid grid-cols-2 md:grid-cols-2 items-center p-6'>
          <div className='w-full h-screen max-w-[1000px] mx-auto flex flex-col justify-center'>
            <Fade direction='left' duration={1000}>
              <p className='text-[#edca99]'>Hei, Jeg heter</p>
              <h1 className='text-4xl sm:text-5xl font-bold text-[#ccd6f6]'>Philip T. Nguyen</h1>
              <TypeAnimation
                className='text-2xl py-2 sm:text-7xl font-bold text-[#edca99]'
                sequence={[
                  1000, // 1s
                  'IT-Konsulent',
                  1000, // 1s
                  'Tidligere Pedagog',
                  () => {},
                ]}
                wrapper="span"
                cursor={true}
                repeat={Infinity}
                style={{ fontSize: '2em', display: 'inline-block' }}
              />
              
              <p className='text-[#8892b0] py-2 max-w-[700px]'>   
              Gjennom studiene har jeg funnet glede av å jobbe med oppgaver og prosjekter innen IT og utvikling. 
              Jeg er veldig motivert for å lære mer og bygge videre på erfaringene mine, og etter studiene har jeg fått muligheten til å gjøre dette til en del av hverdagen min.
              </p>

              <div>
                <Link onClick={handleClick} to="work" smooth={true} duration={500}>
                  <button className='text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-blue-600 hover:border-blue-600'>
                    <span className='group-hover:-rotate-90 duration-200'>
                      <HiArrowNarrowLeft className='mr-2' />
                    </span>
                    Se mine prosjekter
                    <span className='group-hover:rotate-90 duration-200'>
                      <HiArrowNarrowRight className='ml-2' />
                    </span>
                  </button>
                </Link>
        
              </div>
            </Fade>
          </div>

          <div className='pl-8'>
            <Fade direction='down' duration={800}>
              <img className='mx-auto rounded-lg hover:scale-110 transform transition duration-500 ' src={Me} alt="Me" /> 
            </Fade>
          </div>   
        </div>
      </div>
      



    </div>
  );
}

export default Home;
