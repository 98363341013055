import About from "./components/About";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Skills from "./components/Skills";
import Work from "./components/Work";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import React, { useRef, useEffect } from 'react';

const assetUrl = (name, wrap = false) => 
  `${wrap ? 'url(' : ''}https://awv3node-homepage.surge.sh/build/assets/${name}.svg${wrap ? ')' : ''}`;

function App() {


  return (
    
    <div>
     <Navbar/>
      <Home/>
      <About />
      <Skills />
      <Work />
      <Contact />
 
      <Footer/>
    </div>
    
  );
}

export default App;
