import React, {useState} from 'react';
import {FaBars, FaTimes, FaGithub, FaLinkedin, FaFacebook} from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi';
import {BsFillPersonLinesFill} from 'react-icons/bs'
import Logo from '../assets/logo.png'

import {Link} from 'react-scroll'
import { Fade, Slide, Zoom } from "react-awesome-reveal";
const Navbar = () => {
  const[nav, setNav] = useState(false)
  const handleClick = () => setNav(!nav)

    return (
      
    <div className='fixed w-full h-[100px] flex justify-end items-center px-4 text-gray-300 z-50'>
        
      {/*<Slide>
        <div>
            <img src={Logo} alt= "Logo Image" style={{width: '100px'}}/>
        </div>
        </Slide>
        */}
            <ul className='hidden md:flex'>
           
                <li className=' hover:text-gray-400'>
                <Link to="home" smooth={true} duration={500}>
                Hjem
                </Link>
                </li>
                <li className='hover:text-gray-400'>
                <Link to="about" smooth={true} duration={500}>
                Om meg
                </Link>
                </li>
                <li className='hover:text-gray-400'>
                <Link to="skills" smooth={true} duration={500}>
                Ferdigheter
                </Link>
                </li>
                <li className='hover:text-gray-400'>
                <Link to="work" smooth={true} duration={500}>
                Mine prosjekter
                </Link>
                </li>
                <li className='hover:text-gray-400'>
                <Link to="contact" smooth={true} duration={500}>
                Kontakt
                </Link>
                </li>
              
            </ul>
        
        {/*Hamburger meny*/}
        
        <div onClick={handleClick} className='md:hidden z-10'>
            <div className='absolute right-8'>
              {!nav ? <FaBars/> : <FaTimes/>}
            </div>
        </div>

        {/* Mobile menu */}
        
        <ul className={!nav ? 'hidden' :  'absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center'}>
        <Zoom>
                <li className='py-6 text-4xl hover:text-gray-400'>
                <Link onClick={handleClick} to="home" smooth={true} duration={500}>
                Hjem
                </Link>
                </li>
                <li className='py-6 text-4xl hover:text-gray-400'>
                <Link onClick={handleClick} to="about" smooth={true} duration={500}>
                Om meg
                </Link>
                </li>
                <li className='py-6 text-4xl hover:text-gray-400'>
                <Link onClick={handleClick} to="skills" smooth={true} duration={500}>
                Ferdigheter
                </Link>
                </li>
                <li className='py-6 text-4xl hover:text-gray-400'>
                <Link onClick={handleClick} to="work" smooth={true} duration={500}>
                Mine prosjekter
                </Link>
                </li>
                <li className='py-6 text-4xl hover:text-gray-400'>
                <Link onClick={handleClick} to="contact" smooth={true} duration={500}>
                Kontakt
                </Link>
                </li>
                </Zoom>
                
                
                <div className='grid grid-cols-4 w-full fixed flex-col bottom-[0%] text-center'>
                  <Slide direction='up'>
                  <li className='py-6 text-xl hover:text-gray-400 bg-blue-600'>
                  <a 
                  href="https://www.linkedin.com/in/philip-toan-nguyen/"> Linkedin
                  </a>        
                  </li>
                  <li className='py-6 text-xl hover:text-gray-400 bg-slate-600'>
                  <a 
                  href="https://github.com/PhilipToanNguyen"> Github
                  </a> 
                  </li>
                  <li className='py-6 text-xl hover:text-gray-400 bg-yellow-600'>
                  <a  
                    href="mailto:philiptoannguyen@gmail.com"> Mail
                 
                   </a>
                  </li>
                  <li className='py-6 text-xl hover:text-gray-400 bg-green-600'>
                  <a
                href="https://drive.google.com/file/d/18U52-OqRdG2imjEMhqpjcRS0jMXZ0BNo/view?usp=sharing" > CV
                 
                  </a>
                  </li>
                  </Slide>
                </div>
                
        </ul>
        

         {/* Social icons */}
         <div className='hidden lg:flex fixed flex-col top-[25%] left-0'>
            <ul>
              <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600'>
                <a className='flex justify-between items-center w-full text-gray-300' 
                href="https://www.linkedin.com/in/philip-toan-nguyen/">
                   Linkedin <FaLinkedin size={30}/>
                </a>
                </li>  
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-slate-600'>
                <a className='flex justify-between items-center w-full text-gray-300' 
                href="https://github.com/PhilipToanNguyen">
                   Github <FaGithub size={30}/>
                </a>
                </li>  
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-yellow-600'>
                <a className='flex justify-between items-center w-full text-gray-300' 
                href="mailto:philiptoannguyen@gmail.com">
                   <p>Min Mail</p> <HiOutlineMail size={30}/>
                </a>
                </li>  
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-green-600'>
                <a className='flex justify-between items-center w-full text-gray-300' 
                href="https://drive.google.com/file/d/18U52-OqRdG2imjEMhqpjcRS0jMXZ0BNo/view?usp=sharing" >
                  Min CV <BsFillPersonLinesFill size={30}/>
                </a>
                </li>  
            </ul>
         </div>
    </div>
  )
}

export default Navbar