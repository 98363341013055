import React from 'react';

const Contact = () => {
  return (
    <div name='contact' className='w-full h-screen bg-[#0a192f] flex justify-center items-center p-4'>
      <form 
        method='POST' 
        action='https://getform.io/f/555a0c03-2852-45aa-974e-91ae479b4e3d' 
        className='flex flex-col max-w-[800px] w-full bg-[#0a192f] p-12 rounded-md border-2 border-[#edca99]'
      >
        <div className='flex flex-col md:flex-row justify-between items-center pb-2'>
          <div className='text-center md:text-left'>
            <p className='text-4xl font-bold border-b-4 border-[#edca99] text-gray-300 inline-block'>
              Ta kontakt
            </p>
            <p className='text-gray-300 py-4 text-lg'>
              Fyll ut skjemaet eller send meg en mail -{' '}
              <a 
                href='mailto:philiptoannguyen@gmail.com' 
                className='text-[#edca99] hover:underline'
              >
                philiptoannguyen@gmail.com
              </a>
            </p>
          </div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          <input 
            className='bg-[#ccd6f6] p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#edca99] text-[#0a192f]' 
            type='text' 
            placeholder='Navn' 
            name='navn' 
            required 
          />
          <input 
            className='bg-[#ccd6f6] p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#edca99] text-[#0a192f]' 
            type='email' 
            placeholder='Email' 
            name='email' 
            required 
          />
        </div>
        <textarea 
          className='bg-[#ccd6f6] p-3 rounded-lg mt-4 focus:outline-none focus:ring-2 focus:ring-[#edca99] text-[#0a192f]' 
          name='message' 
          rows='6' 
          placeholder='Melding' 
          required
        ></textarea>
        <button 
          className='text-white border-2 hover:bg-blue-600 hover:border-blue-600 px-6 py-3 mt-6 mx-auto flex items-center rounded-lg transition duration-300'
        >
          Send melding
        </button>
      </form>
    </div>
  );
};

export default Contact;
