import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HTML from '../assets/html.png';
import CSS from '../assets/css.png';
import JavaScript from '../assets/javascript.png';
import ReactImg from '../assets/react.png';
import GitHub from '../assets/github.png';
import Tailwind from '../assets/tailwind.png';
import Java from '../assets/java.png';
import Bootstrap from '../assets/bootstrap.png';
import MySQL from '../assets/mysql.png';
import Nextjs from '../assets/nextjs.png';
import Kotlin from '../assets/kotlin.png';
import Blazor from '../assets/blazor.png';
import Specflow from '../assets/specflow.png';
import Reqnroll from '../assets/reqnroll.png';
import Firebase from '../assets/firebase.png';
import Csharp from '../assets/csharp.png'
import { Fade } from 'react-awesome-reveal';
import { Zoom, Slide } from "react-awesome-reveal";

{/*https://react-slick.neostack.com/*/}

const Skills = () => {
  const images = [
    HTML, CSS, Tailwind, Bootstrap, ReactImg, JavaScript,
    Java, GitHub, MySQL, Nextjs, Kotlin, Firebase, Blazor, Csharp
  ];

  const getTechName = (index) => {
    const techNames = ['HTML', 'CSS', 'Tailwind CSS', 'Bootstrap', 'React', 'JavaScript',
      'Java', 'GitHub', 'MySQL', 'Next.js', 'Kotlin', 'Firebase', 'Blazor', 'C#'];
    return techNames[index];
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 1000,
    cssEase: "ease-out",
    arrows: false,
    swipe: false,       
    draggable: false,  
    pauseOnHover: true, 
 
  };

  return (
    <div name='skills' className=' w-full h-screen  bg-[#0a192f] text-gray-300' >
      {/* Container */}
      <div className='max-w-[1000px] mx-auto flex flex-col justify-center text-center'>
        <div className=''>
          <Fade direction=''>
            <p className='text-4xl font-bold inline border-b-4 border-[#edca99] p-2'>Ferdigheter</p>
          </Fade>
          <Fade direction=''>
            <p className='py-8'>Teknologier jeg har kjennskap til og jobbet med i prosjekter</p>
          </Fade>
        </div>

      <Slide direction='up'> 
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className='hover:scale-110 duration-1000 p-2'>
              <img className='mx-auto' src={image} alt={`icon ${index}`} />
              <p className='my-8'>{getTechName(index)}</p>
            </div>
          ))}
        </Slider>
    </Slide> 

    
      </div>
    </div>
  );
};



export default Skills;