import React from 'react'
import Me from '../assets/me.png'
import { Slide } from 'react-awesome-reveal'

const About = () => {
  return (
  <div name='about' className='w-full h-screen bg-[#0a192f] text-gray-300'>
    <div className=' flex flex-col justify-center items-center w-full h-full'>
        <div className='max-w-[1000px] w-full grid grid-cols-2 '>
           
            <div className='sm:text-right pb-8 pl-4'>
                <Slide direction='down'>
                <p className='text-4xl font-bold inline border-b-4 py-4 border-[#edca99]'>
                    Om meg
                    </p>
                </Slide>    
            </div>
            
            </div>
            
            <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-2 p-2'>
                
                <div className='sm:text-right text-4xl font-bold leading-normal pr-2 '>
                <Slide direction='left'>
                    <p className='text-lg'>
                    Dette er min porteføljeside.
                    Jeg setter pris på at du har merket og tatt deg tid til å utforske nettsiden min.
                    Det er et prosjekt jeg har tatt meg tid og innsats å utvikle på fritiden min.</p>
                </Slide>    
                </div>
                
                <div>
                <Slide direction='right'>
                    <p className='border-l-4 pl-4 border-[#edca99] '>
                    Som en 27 år gammel mann med tidligere erfaring innen pedagogikk, 
                    har jeg glede av å arbeide med mennesker.
                    Men jeg har alltid hatt en sterk interesse innen IT-området, 
                    noe som har ledet meg til å ta beslutningen om å ta en bachelorgrad.
                    <br></br>
                    Til tross for at jeg var student, hadde jeg min deltidsjobb og
                    engasjement i frivillig arbeid. Selv om dette ofte var utfordrende, 
                    trivdes jeg med å møte situasjoner som ga meg læring og erfaring.

                    </p>
                </Slide>
                </div>

            </div>
            
        </div>
    </div>
  
  )
}

export default About