import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import WorkImg from '../assets/workImg.jpeg';
import WorkImg2 from '../assets/workImg2.jpeg';
import WorkImg3 from '../assets/workImg3.jpeg';
import WorkImg4 from '../assets/workImg4.jpeg';
import WorkImg5 from '../assets/workImg5.jpeg';
import WorkImg6 from '../assets/workImg6.jpeg';
import WorkImg7 from '../assets/workImg7.jpeg';
import WorkImg8 from '../assets/workImg8.jpeg';
import WorkImg9 from '../assets/workImg9.jpeg';
import WorkImg10 from '../assets/workImg10.jpeg';
import WorkImg11 from '../assets/workImg11.jpeg';
import WorkImg12 from '../assets/workImg12.jpeg';
import WorkImg13 from '../assets/workImg13.jpg';
import { Zoom, Slide, Fade } from 'react-awesome-reveal';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const Work = () => {
  const sliderRef = useRef(null);

  const projects = [
    {
      image: WorkImg,
      title: 'Grunnleggende HTML, CSS, og JavaScript.',
      demoLink: 'https://philiptoannguyen.github.io/Grunnleggende-Nettside/',
      codeLink: 'https://github.com/PhilipToanNguyen/Grunnleggende-Nettside',
    },
    {
      image: WorkImg12,
      title: 'Bacheloroppgave med Visma som oppdragsgiver',
      demoLink: 'https://drive.google.com/file/d/1FHlrAczU-T9KWUIb3XbrFgfwfdsYfSk4/view?usp=sharing',
      codeLink: 'https://gruppe5bop3000.wordpress.com/',
    },
    {
      image: WorkImg13,
      title: 'Utviklet nettside der man kan søke etter pokemonkort og pris: API, JS, React, TailwindCSS',
      demoLink: 'https://philiptoannguyen.github.io/Pokemon-TCG-Searcher/',
      codeLink: 'https://github.com/PhilipToanNguyen/Pokemon-TCG-Searcher',
    },

    {
      image: WorkImg2,
      title: 'Pac-Man i Java (JavaFX).',
      demoLink: 'https://drive.google.com/file/d/1K4chLh3zFLEQeRpDWe7pRnXUdargrgAG/view?usp=sharing',
      codeLink: 'https://github.com/PhilipToanNguyen/Pacman',
    },
    {
      image: WorkImg3,
      title: 'PaintFX i Java (JavaFX).',
      demoLink: 'https://drive.google.com/file/d/1FChyS2z-vFqDmDK3_Stj2f-pcsYfIF0O/view?usp=sharing',
      codeLink: 'https://github.com/PhilipToanNguyen/Paint',
    },
    {
      image: WorkImg4,
      title: 'OOP, design og animasjon med Java (Swings).',
      demoLink: 'https://drive.google.com/file/d/1kLOUzvc9QrXXPS-vv6zf80CzNKuqCNjm/view?usp=sharing',
      codeLink: 'https://github.com/PhilipToanNguyen/My2DGame',
    },
    {
      image: WorkImg5,
      title: 'Personlig album. JavaScript.',
      demoLink: 'https://philiptoannguyen.github.io/personalalbum/',
      codeLink: 'https://github.com/PhilipToanNguyen/personalalbum',
    },
    {
      image: WorkImg6,
      title: 'Prototypeutvikling for en kunde.',
      demoLink: 'https://haavga.github.io/PRO1000_Spegle/index.html',
      codeLink: 'https://github.com/HaavGa/PRO1000_Spegle',
    },
    {
      image: WorkImg7,
      title: 'Sjakk-trener i MERN-stack.',
      demoLink: 'https://pacific-retreat-14389.herokuapp.com',
      codeLink: 'https://github.com/Adjo00/APP2000_Gruppe-07-SjakkTrener',
    },
    {
      image: WorkImg8,
      title: 'Værapplikasjon med API-integrasjon.',
      demoLink: 'https://philiptoannguyen.github.io/weather-app-react/',
      codeLink: 'https://github.com/PhilipToanNguyen/weather-app-react',
    },
    {
      image: WorkImg9,
      title: 'Skiltsøkern i Kotlin.',
      demoLink: 'https://drive.google.com/file/d/1-cj1clcICQQmwsvKZqPxeuZNZUi0jLOl/view?usp=sharing',
      codeLink: 'https://github.com/Danofe/MOB3000',
    },
    {
      image: WorkImg10,
      title: 'React Parallax-nettside.',
      demoLink: 'https://philiptoannguyen.github.io/parallax-react/',
      codeLink: 'https://github.com/PhilipToanNguyen/parallax-react',
    },
    {
      image: WorkImg11,
      title: 'Chatapp med React og Firebase.',
      demoLink: 'https://philiptoannguyen.github.io/my-react-chatapp/',
      codeLink: 'https://github.com/PhilipToanNguyen/my-react-chatapp',
    },
  ];

  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    swipeToSlide: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    prevArrow: (
      <button className='absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-700 text-white p-3 rounded-full hover:bg-gray-800 transition-all'>
        <FaChevronLeft />
      </button>
    ),
    nextArrow: (
      <button className='absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-700 text-white p-3 rounded-full hover:bg-gray-800 transition-all'>
        <FaChevronRight />
      </button>
    ),
  };

  const handleSlideClick = (event) => {
    const containerWidth = sliderRef.current.offsetWidth;
    const clickPosition = event.clientX;

    if (clickPosition < containerWidth / 2) {
      sliderRef.current.slickPrev(); // Gå til forrige slide ved venstreklikk
    } else {
      sliderRef.current.slickNext(); // Gå til neste slide ved høyreklikk
    }
  };

  return (
    <div name='work' className='w-full min-h-screen text-gray-300 bg-[#0a192f]'>
      <div className='max-w-[1000px] mx-auto py-12 flex flex-col justify-center p-2'>
        <div className='pb-8'>
          <Fade>
            <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-[#edca99]'>Prosjekter</p>
            <p className='py-6'>Utforsk mine tidligere prosjekter og opplæringsprosjekter som jeg har jobbet med både på skolen og på fritiden.</p>
          </Fade>
        </div>

       
          <div onClick={handleSlideClick}>
            <Slider ref={sliderRef} {...settings}>
              {projects.map((project, index) => (
                <div key={index} className='relative'>
                  <div
                    style={{
                      backgroundImage: `url(${project.image})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      height: '400px',
                      borderRadius: '10px',
                    }}
                    className='shadow-lg group container flex justify-center items-center mx-auto p-4 hover:shadow-2xl transition-shadow duration-300'
                  >
                    <div className='opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out'>
                      <div className='bg-black bg-opacity-70 p-6 rounded-lg'>
                        <p className='text-xl font-bold text-white tracking-wider text-center mb-4'>{project.title}</p>
                        <div className='text-center'>
                          <a href={project.demoLink} target='_blank' rel='noopener noreferrer'>
                            <button className='text-center rounded-lg px-4 py-2 m-2 bg-[#edca99] text-gray-800 font-bold text-lg hover:bg-[#edaa66] transition-all duration-300'>Demo / Rapport</button>
                          </a>
                          <a href={project.codeLink} target='_blank' rel='noopener noreferrer'>
                            <button className='text-center rounded-lg px-4 py-2 m-2 bg-[#edca99] text-gray-800 font-bold text-lg hover:bg-[#edaa66] transition-all duration-300'>Kode / Dokumentasjon</button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
     
      </div>
    </div>
  );
};

export default Work;
